import "../styles/globals.css";
import "@uppy/core/dist/style.css";
import "@uppy/dashboard/dist/style.css";
import "@uppy/image-editor/dist/style.css";
import "core-js/stable";
import "regenerator-runtime/runtime";
import "simplebar-react/dist/simplebar.min.css";
import DatadogInit from "../frontend/datadog/datadog-init";

import { useAtomValue } from "jotai";
import AppLoader from "../frontend/loader/app-loader";
import { StateProvider } from "../frontend/state";
import { isBusyAtom, userAtom } from "../state-atoms";
import { useEffect } from "react";
import * as Sentry from "@sentry/nextjs";
import { addTestUtils } from "../frontend/utils/test-utils";
import { PortalID } from "frontend/modal/portal-types";
import posthog from "posthog-js";
import { PostHogProvider } from "posthog-js/react";
import tracking from "frontend/tracking";
import { setUpAnalyticTools } from "utils/analytic-utils";

// Init the tracking applications only on client-side
if (typeof window !== "undefined") {
  tracking.initMixPanelClient();
  tracking.initPostHogClient();
}

// eslint-disable-next-line react/prop-types
function MyApp({ Component, pageProps }) {
  const isBusy = useAtomValue(isBusyAtom);
  const user = useAtomValue(userAtom);
  // Define originalFetch outside to apply to first requests
  const originalFetch = typeof window !== "undefined" ? window.fetch : null;

  // Override fetch if in preview environment
  if (typeof window !== "undefined" && process.env.NEXT_ENV === "preview-per-pr") {
    const originalFetch = window.fetch;

    window.fetch = async (...args) => {
      let [resource, config] = args;

      // Check if the resource URL matches the specific domains
      const allowedDomains = ["preview.dev.workcanvas.com", "preview-api.dev.workcanvas.com"];
      const resourceUrl = new URL(resource);

      if (allowedDomains.some((domain) => resourceUrl.hostname.includes(domain))) {
        // Initialize config and headers if not present
        if (!config) config = {};
        if (!config.headers) config.headers = {};

        // Add X-Branch-Path header with the environment variable
        config.headers["X-Branch-Path"] = process.env.NEXT_BRANCH_PATH || "preview";

        // Log the headers for debugging
        console.log("Headers added: ", config.headers);
      }

      // Call the original fetch with the modified config
      return originalFetch(resource, config);
    };
  }

  useEffect(() => {
    if (user) {
      Sentry.setUser({ id: user.id, accountId: user.account?.id, email: user.email });
      addTestUtils(user.account.id);
      setUpAnalyticTools(user);
    }

    console.log("NEXT_ENV", process.env.NEXT_ENV);

    // Cleanup to restore original fetch
    return () => {
      if (typeof window !== "undefined" && originalFetch) {
        window.fetch = originalFetch;
      }
    };
  }, [user]);

  return (
    <StateProvider>
      <DatadogInit />
      <PostHogProvider client={posthog}>
        <Component {...pageProps} />
        <div className={"checkout"} id={PortalID.Checkout} />
        <div className={"curtain"} hidden={!isBusy}>
          <AppLoader />
        </div>
      </PostHogProvider>
    </StateProvider>
  );
}

export default MyApp;
