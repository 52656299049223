import mondaySdk from "monday-sdk-js";

const monday = mondaySdk();

export enum MondayUserBoardPermissions {
  "view",
  "edit",
}

enum MondayBoardPermissions {
  everyone = "everyone",
  owners = "owners",
  collaborators = "collaborators",
  assignee = "assignee",
}

async function getMondayBoard(boardId: number) {
  const query = `query {
    boards(ids: ${boardId}) {
      id
      owners {
        id
        name
        email
      }
      subscribers {
        id
        name
        email
      }
      permissions
    }
  }`;
  const response = await monday.api(query);

  if (!response.data?.boards?.length) {
    return null;
  }

  return response.data.boards[0];
}

export async function getUserIdFromMondayContext() {
  const mondaySDKContextResponse = await monday.get("context");
  const mondaySDKContextData = mondaySDKContextResponse.data;
  const userId = "user" in mondaySDKContextData ? mondaySDKContextData.user.id : null;
  return userId;
}

// This logic is temporary till we will have a proper way to get the permissions from monday
// custom objects have objectPermissions field, but boards views don't have it
// monday viewer users can't do api calls at all, so we can't get the permissions from the api only from the context
// for non viewer users we query api to get the board permissions and check if the user is owner or subscriber
export async function getAccessPermissionToMondayBoard() {
  const mondaySDKContextResponse = await monday.get("context");
  const mondaySDKContextData = mondaySDKContextResponse.data;
  const objectPermissions = "objectPermissions" in mondaySDKContextData ? mondaySDKContextData.objectPermissions : null;
  if (objectPermissions) {
    return objectPermissions === "edit" ? MondayUserBoardPermissions.edit : MondayUserBoardPermissions.view;
  }
  const isViewOnly = "user" in mondaySDKContextData ? mondaySDKContextData.user.isViewOnly : null;
  if (isViewOnly) {
    return MondayUserBoardPermissions.view;
  }

  const boardId = "boardId" in mondaySDKContextData ? mondaySDKContextData.boardId : null;
  if (!boardId || typeof boardId !== "number") {
    return MondayUserBoardPermissions.view;
  }

  const board = await getMondayBoard(boardId);
  if (board.permissions === MondayBoardPermissions.everyone) {
    return MondayUserBoardPermissions.edit;
  }
  const userId = await getUserIdFromMondayContext();
  if (board.permissions === MondayBoardPermissions.owners && board.owners.some((owner: any) => owner.id === userId)) {
    return MondayUserBoardPermissions.edit;
  }
  if (
    board.permissions === MondayBoardPermissions.collaborators &&
    board.subscribers.some((owner: any) => owner.id === userId)
  ) {
    return MondayUserBoardPermissions.edit;
  }
  return MondayUserBoardPermissions.view;
}
