import React, { createContext, useContext, useReducer, useMemo } from "react";
import LoadedState from "./loadedState";
import reducer from "./reducers";
import { appStateSchema } from "shared/datamodel/schemas/app-state";
import { MondayUserBoardPermissions } from "frontend/services/monday-api-service";

export { Action, DispatchEvent } from "./actions";

function createInitialState() {
  return appStateSchema.parse({
    isLoggedIn: false,
    user: null,
    userState: LoadedState.loading,
    account: null,
    boardsState: LoadedState.initial,
    boards: [],
    usersState: LoadedState.initial,
    users: new Map(),
    activePromotion: null,
    teams: [],
    mondayBoardAccessPermission: MondayUserBoardPermissions.view,
  });
}

const StateContext = createContext(null);

// eslint-disable-next-line react/prop-types
export function StateProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, createInitialState());
  const value = useMemo(() => [state, dispatch], [state, dispatch]);
  return <StateContext.Provider value={value}>{children}</StateContext.Provider>;
}

export function useStateValue() {
  return useContext(StateContext);
}
