export enum IntegrationType {
  monday = "monday",
}

export enum MondayIntegrationType {
  tasks = "tasks",
  org_chart = "org_chart",
  live = "live",
}

export type ChartsFullDataLoadedState = Record<string, boolean>;
